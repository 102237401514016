import { UserManager } from "oidc-client";
import Layout from "../Layout";
import { useAppSelector } from "../../hooks";
import {
  selectCustomer,
  selectOrderOptions,
  selectUser,
  setOrderInformation,
} from "../../store/reducers/userReducer";
import Title from "../ui/Title";
import { Icon } from "../ui/Icon";
import Login from "../Login";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "../ui/Input";
import Summary from "./Summary";
import { Button } from "../ui/Button";
import { OrderInformation, Distributor, Address } from "../../types";
import DistributorSelect from "./DistributorSelect";
import { useDispatch } from "react-redux";
import {
  validGuestOrderInformationSchema,
  validOrderInformationSchema,
} from "../../validation";
import { getDefaultOrderInformation } from "../../utils";
import { useFetchDistributorsQuery } from "../../api/nest";
import PrismicTranslation from "../PrismicTranslation";
import {EditTitle} from "../Project/EditTitle";

const Delivery = ({ userManager }: { userManager: UserManager }) => {
  const customer = useAppSelector((state) => selectCustomer(state));
  const user = useAppSelector((state) => selectUser(state));
  const orderOptions = useAppSelector((state) => selectOrderOptions(state));
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] =
    useState<Address>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [differentShippingAddress, setDifferentShippingAddress] =
    useState<boolean>(false);
  const [errors, setErrors] = useState(undefined);
  const [formValues, setFormValues] = useState<OrderInformation>(
    getDefaultOrderInformation(customer, user, orderOptions),
  );

  const [formTouched, setFormTouched] = useState(false);
  const isLoggedIn = !!user;
  const isGuest = user?.scope === "guest";
  const { invoiceAdress, deliveryAddresses } = orderOptions || {};

  const { data: distributors } = useFetchDistributorsQuery({});


  useEffect(() => {
    const defaultDistributor = distributors?.find(
      (distributor: Distributor) =>
        distributor.id?.toString() === customer?.code,
    );
    handleFormValue("distributor", defaultDistributor);
  }, [distributors, customer]);

  useEffect(() => {
    if (deliveryAddresses) {
      setSelectedDeliveryAddress(deliveryAddresses[0]);
    }
  }, [customer]);

  const getFormError = (fieldName: string) => {
    // @ts-ignore
    return errors && errors[fieldName] && errors[fieldName][0];
  };

  const validate = (values: OrderInformation) => {
    const validationResult = isGuest
      ? validGuestOrderInformationSchema.safeParse(values)
      : validOrderInformationSchema.safeParse(values);

    if (validationResult.success) {
      setErrors(undefined);
    } else {
      const errors = validationResult.error.formErrors.fieldErrors;
      // @ts-ignore
      setErrors(errors);
    }
  };

  const handleFormValue = (
    field: string,
    value?: string | number | Distributor,
  ) => {
    const newFormValues = { ...formValues, [field]: value };
    setFormValues(newFormValues);
    validate(newFormValues);
    setFormTouched(true);
  };

  const handleBillingAddressValue = (field: string, value: string | number) => {
    const newFormValues = {
      ...formValues,
      billingAddress: { ...formValues?.billingAddress, [field]: value },
    };
    setFormValues(newFormValues);
    validate(newFormValues);
    setFormTouched(true);
  };

  const handleShippingAddressValue = (
    field: string,
    value: string | number,
  ) => {
    const newFormValues = {
      ...formValues,
      shippingAddress: { ...formValues?.shippingAddress, [field]: value },
    };
    setFormValues(newFormValues);
    validate(newFormValues);
    setFormTouched(true);
  };

  const handleOrder = () => {
    validate(formValues);

    if (!errors) {
      dispatch(setOrderInformation(formValues));
      navigate(`/review/${projectId}`);
    }
  };

  const selectDeliveryAddress = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedAddress = deliveryAddresses.find(
      (address: Address) => address.id === e.target.value,
    );
    setSelectedDeliveryAddress(selectedAddress);
    const newFormValues = {
      ...formValues,
      shippingAddress: selectedAddress,
    };
    setFormValues(newFormValues);
    validate(newFormValues);
    setFormTouched(true);
  };

  return (
    <Layout userManager={userManager} background={!isLoggedIn}>
      {isLoggedIn ? (
        <div className="flex flex-wrap flex-1 h-full">
          <div className="bg-gray flex flex-col w-full lg:w-2/3 pt-4 lg:px-10">
            <div
              onClick={() => navigate(`/cart/${projectId}`)}
              className="cursor-pointer flex items-center gap-2 ml-2 underline text-text text-sm"
            >
              <Icon icon="back" size="small" />
              <span>
                <PrismicTranslation field="breadcrumb_cart" simple />
              </span>
            </div>
            <div className="mb-8">
              <Title label="delivery_information" />
              {isGuest ? (
                <div className="bg-white p-4">
                  <Title
                    tertiary
                    className="text-black"
                    label="contact_information"
                  />
                  <div>
                    <Input
                      alternateLabel
                      defaultValue={user?.email}
                      isError={!!getFormError("email")}
                      errorLabel={getFormError("email")}
                      onChange={(e) => handleFormValue("email", e.target.value)}
                      label="email"
                    />
                    <DistributorSelect
                      options={distributors}
                      current={formValues?.distributor}
                      onSelect={handleFormValue}
                    />

                  </div>
                </div>
              ) : (
                <div className="bg-white p-4 h-full">
                  <Title tertiary label="company_details" />
                  <Input
                    alternateLabel
                    readOnly
                    defaultValue={customer?.name}
                    onChange={(e) =>
                      handleFormValue("companyName", e.target.value)
                    }
                    label="company_name"
                  />
                  <Title
                    tertiary
                    className="text-black"
                    label="contact_information"
                  />
                  <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-1">
                      <Input
                        alternateLabel
                        readOnly
                        isError={!!getFormError("firstName")}
                        errorLabel={getFormError("firstName")}
                        defaultValue={user?.fullName}
                        onChange={(e) =>
                          handleFormValue("firstName", e.target.value)
                        }
                        label="full_name"
                      />
                      {user?.phone_number && (
                        <Input
                          alternateLabel
                          readOnly
                          isError={!!getFormError("phone")}
                          errorLabel={getFormError("phone")}
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          defaultValue={user?.phone_number}
                          onChange={(e) =>
                            handleFormValue("phone", e.target.value)
                          }
                          label="phone"
                        />
                      )}
                    </div>
                    {user?.email && (
                      <div className="col-span-1">
                        <Input
                          alternateLabel
                          readOnly
                          isError={!!getFormError("email")}
                          errorLabel={getFormError("email")}
                          defaultValue={user?.email}
                          onChange={(e) =>
                            handleFormValue("email", e.target.value)
                          }
                          label="email"
                        />
                      </div>
                    )}
                  </div>
                  <div>
                    <Title
                      tertiary
                      className="text-black"
                      label="billing_address"
                    />
                    <Input
                      isError={!!getFormError("billingAddress.street")}
                      errorLabel={getFormError("billingAddress.street")}
                      alternateLabel
                      onChange={(e) =>
                        handleBillingAddressValue("street", e.target.value)
                      }
                      defaultValue={invoiceAdress?.street}
                      label="street"
                    />
                    <Input
                      alternateLabel
                      isError={!!getFormError("billingAddress.street2")}
                      errorLabel={getFormError("billingAddress.street2")}
                      onChange={(e) =>
                        handleBillingAddressValue("street2", e.target.value)
                      }
                      defaultValue={invoiceAdress?.street2}
                      label="street2"
                    />
                    <Input
                      alternateLabel
                      isError={!!getFormError("billingAddress.postalCode")}
                      errorLabel={getFormError("billingAddress.postalCode")}
                      defaultValue={invoiceAdress?.zipCode}
                      onChange={(e) =>
                        handleBillingAddressValue("postalCode", e.target.value)
                      }
                      label="zip"
                    />
                    <Input
                      alternateLabel
                      defaultValue={invoiceAdress?.city}
                      isError={!!getFormError("billingAddress.city")}
                      errorLabel={getFormError("billingAddress.city")}
                      onChange={(e) =>
                        handleBillingAddressValue("city", e.target.value)
                      }
                      label="city"
                    />
                    <Input
                      alternateLabel
                      isError={!!getFormError("billingAddress.country")}
                      errorLabel={getFormError("billingAddress.country")}
                      defaultValue={invoiceAdress?.country}
                      onChange={(e) =>
                        handleBillingAddressValue("country", e.target.value)
                      }
                      label="country"
                    />
                  </div>
                  {differentShippingAddress && (
                    <div>
                      <Title
                        tertiary
                        className="text-black"
                        label="shipping_address"
                      />
                      {deliveryAddresses?.length > 1 && (
                        <select
                          className="mb-4 border rounded p-2 border-gray"
                          onChange={selectDeliveryAddress}
                          defaultValue={deliveryAddresses[0]?.id}
                        >
                          {deliveryAddresses?.map((address: Address) => (
                            <option key={address.id} value={address.id}>
                              {address.name}
                            </option>
                          ))}
                        </select>
                      )}
                      <Input
                        alternateLabel
                        readOnly={deliveryAddresses?.length}
                        isError={!!getFormError("shippingAddress.street")}
                        errorLabel={getFormError("shippingAddress.street")}
                        onChange={(e) =>
                          handleShippingAddressValue("street", e.target.value)
                        }
                        value={selectedDeliveryAddress?.street}
                        label="street"
                      />
                      {selectedDeliveryAddress?.street2 &&
                        !deliveryAddresses?.length && (
                          <Input
                            alternateLabel
                            readOnly={deliveryAddresses?.length}
                            isError={!!getFormError("shippingAddress.street2")}
                            errorLabel={getFormError("shippingAddress.street2")}
                            onChange={(e) =>
                              handleShippingAddressValue(
                                "street2",
                                e.target.value,
                              )
                            }
                            value={selectedDeliveryAddress?.street2}
                            label="street2"
                          />
                        )}
                      <Input
                        readOnly={deliveryAddresses?.length}
                        isError={!!getFormError("shippingAddress.zipCode")}
                        errorLabel={getFormError("shippingAddress.zipCode")}
                        alternateLabel
                        value={selectedDeliveryAddress?.zipCode}
                        onChange={(e) =>
                          handleShippingAddressValue("zipCode", e.target.value)
                        }
                        label="zip"
                      />
                      <Input
                        alternateLabel
                        readOnly={deliveryAddresses?.length}
                        isError={!!getFormError("shippingAddress.city")}
                        errorLabel={getFormError("shippingAddress.city")}
                        value={selectedDeliveryAddress?.city}
                        onChange={(e) =>
                          handleShippingAddressValue("city", e.target.value)
                        }
                        label="city"
                      />
                      <Input
                        alternateLabel
                        readOnly={deliveryAddresses?.length}
                        isError={!!getFormError("shippingAddress.country")}
                        errorLabel={getFormError("shippingAddress.country")}
                        value={selectedDeliveryAddress?.country}
                        onChange={(e) =>
                          handleShippingAddressValue("country", e.target.value)
                        }
                        label="country"
                      />
                    </div>
                  )}
                  {!differentShippingAddress && (
                    <Button
                      icon="plus"
                      onClick={() => setDifferentShippingAddress(true)}
                      link
                      label="add_shipping_address"
                    />
                  )}
                  {!customer && (
                    <DistributorSelect
                      options={distributors}
                      current={formValues?.distributor}
                      onSelect={handleFormValue}
                    />
                  )}
                </div>
              )}

            {/** PROJECT */}
            <div className="mb-8 mt-8">
              <Title label="Project" />
              <div className="bg-white p-4 h-full">
                  {projectId && <EditTitle projectId={projectId} /> }
              </div>
            </div>
            </div>
          </div>
          <div className="flex flex-col p-8 justify-between w-full md:w-1/3 bg-white max-h-[calc(100vh-88px)]">
            <Summary />
            <div className="pr-4 pt-4 bg-white">
              <Button
                full
                disabled={errors || !formTouched}
                onClick={handleOrder}
                label="review_order"
              />
              <p className="text-text font-medium text-sm mt-4">
                <PrismicTranslation field="order_summary_warning_2" simple />
              </p>
            </div>
          </div>
        </div>
      ) : (
        <Login userManager={userManager} />
      )}
    </Layout>
  );
};

export default Delivery;
