import { UserManager } from "oidc-client";
import Layout from "../Layout";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectUser } from "../../store/reducers/userReducer";
import Title from "../ui/Title";
import Login from "../Login";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../ui/Button";
import { setConfigId, setProjectId } from "../../store/reducers/configReducer";
import PrismicTranslation from "../PrismicTranslation";

const Delivery = ({ userManager }: { userManager: UserManager }) => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isLoggedIn = !!user;
  const isGuest = user?.scope === "guest";

  const state = location.state;

  const createNewProject = () => {
    // clear config and project id
    dispatch(setProjectId(undefined));
    dispatch(setConfigId(undefined));
    navigate("/");
  };

  return (
    <Layout userManager={userManager} background={!isLoggedIn}>
      {isLoggedIn ? (
        <div className="flex flex-wrap flex-1 h-full">
          <div className="bg-gray flex flex-col w-full lg:w-2/3 pt-4 lg:px-10">
            <div className="mb-8">
              <Title label="order_placed" />
              <div className="bg-white p-4 h-full">
                <Title tertiary label="thanks_title" />
                <p className="py-4">
                  <PrismicTranslation simple field="order_thanks_message" />
                </p>
                <p className="py-4">
                  <PrismicTranslation simple field="confirmation_details" />
                  <ul>
                    <li>
                      <PrismicTranslation
                        field="order_number"
                        simple
                        options={{
                          orderId: state.orderId,
                        }}
                      />
                    </li>
                  </ul>
                </p>
                <p className="py-4">
                  <PrismicTranslation simple field="processing_info" />
                </p>
                <p className="py-4">
                  <PrismicTranslation simple field="thanks_again" />
                </p>
                <div className="flex gap-4 py-4 align-middle">
                  <Button onClick={createNewProject} label="new_project" />
                  {!isGuest && (
                    <Button
                      link
                      onClick={() => navigate("/orders")}
                      label="view_orders"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Login userManager={userManager} />
      )}
    </Layout>
  );
};

export default Delivery;
