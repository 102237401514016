import Title from "../ui/Title";
import {
  convertLoadedProductToCartItem,
  getCartItemPrice,
  getMininumOrderQuantity,
  getPriceDiscount,
} from "../../utils";
import { Button } from "../ui/Button";
import AddToCartModal from "../Cart/AddToCartModal/AddToCartModal";
import { CartProduct, ConfigLoader } from "../../types";
import {
  selectProjectSetupComplete,
  setPreviousProductConfigId,
} from "../../store/reducers/uiReducer";
import {
  selectConfig,
  selectConfigId,
  selectFabricSupplier,
  selectIsConfigInCart,
  selectIsEditing,
  selectProductInitDone,
  setConfigId,
  setIsEditing,
} from "../../store/reducers/configReducer";
import { addToCart, updateProduct } from "../../store/reducers/cartReducer";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useCallback, useEffect, useState } from "react";
import { selectDiscount } from "../../store/reducers/userReducer";
import usePrevious from "../../hooks/usePrevious";
import {
  useUpdateProductConfigMutation,
  useAddProductConfigToCartMutation,
  useCreateOrUpdateProductConfigMutation,
  useUpdateProductConfigProductImageMutation,
} from "../../api/nest";
import { isEqual, omit, pick } from "lodash";
import PrismicTranslation from "../PrismicTranslation";
import { useGetDocumentByTypeQuery } from "../../api/prismic";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { useNavigate, useParams } from "react-router-dom";

type FetchResult =
  | { data: any }
  | { error: FetchBaseQueryError | SerializedError };

function hasData(result: FetchResult): result is { data: any } {
  return (result as { data: any }).data !== undefined;
}

const ConfiguratorFooter = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();

  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const [isCreatingConfig, setIsCreatingConfig] = useState(false);
  const productInitDone = useAppSelector(selectProductInitDone);
  const discount = useAppSelector(selectDiscount);
  // Deprecate "selectIsEditing" and only use "selectIsConfigInCart"
  const isEditing = useAppSelector(selectIsEditing);
  const isConfigInCart = useAppSelector(selectIsConfigInCart);
  const dispatch = useAppDispatch();
  const config = useAppSelector(selectConfig);
  const configId = useAppSelector(selectConfigId);
  const [cartItem, setCartItem] = useState<CartProduct>(); // The cart item created by "add to cart"
  const fabricSupplier = useAppSelector(selectFabricSupplier);
  const previousConfig = usePrevious(config) as
    | { fabric: object | null }
    | undefined;
  const projectSetupComplete = useAppSelector(selectProjectSetupComplete);
  const [updateProductConfig, { isLoading }] = useUpdateProductConfigMutation();
  const [createProductConfig] = useCreateOrUpdateProductConfigMutation();
  const [updateProductConfigProductImage] =
    useUpdateProductConfigProductImageMutation();
  const [addToCartMutation] = useAddProductConfigToCartMutation();

  const { data: settings } = useGetDocumentByTypeQuery({
    type: "settings",
    lang: "en-us",
  });
  const { data: productGroups } = useGetDocumentByTypeQuery({
    type: "product_group",
    lang: "en-us",
  });
  const { data: products } = useGetDocumentByTypeQuery({
    type: "product",
    lang: "en-us",
  });
  const { data: fabrics } = useGetDocumentByTypeQuery({
    type: "fabric",
    lang: "en-us",
  });
  const { data: productOptions } = useGetDocumentByTypeQuery({
    type: "product_option",
    lang: "en-us",
  });

  const saveConfig = useCallback(async () => {
    if (!projectSetupComplete) {
      return;
    }
    const hasId = typeof config.id === "string" && config.id.length > 0;
    if (hasId) {
      // update product config
      await updateProductConfig({
        config,
      });
    } else if (isCreatingConfig === false) {
      setIsCreatingConfig(true);
      const result = await createProductConfig({
        config,
        inCart: false,
      });
      setIsCreatingConfig(false);
      if (!configId && hasData(result) && result.data.id) {
        dispatch(setConfigId(result.data.id));
      }
    }
  }, [
    configId,
    dispatch,
    config,
    updateProductConfig,
    createProductConfig,
    isCreatingConfig,
    projectSetupComplete,
  ]);

  //
  useEffect(() => {
    if (productInitDone && !configId) {
      saveConfig();
    }
  }, [configId, productInitDone, saveConfig]);

  const cartItemForPrice = {
    ...config,
    ...{
      amount: config.product?.data?.body,
    },
  } as CartProduct;

  const cartItemPrice = getCartItemPrice(cartItemForPrice, fabricSupplier);
  const discountPrice = getPriceDiscount(cartItemPrice, discount);
  const discountedCartItemPrice = cartItemPrice - discountPrice;

  // Add to cart
  const addProductToCart = useCallback(async () => {
    if (!configId) {
      return;
    }
    const response = (await addToCartMutation({ id: configId })) as {
      data: ConfigLoader;
    };
    if (response && response.data) {
      const productConfig = response.data;
      const cartItem = convertLoadedProductToCartItem(
        productConfig,
        products,
        productGroups,
        productOptions,
        fabrics,
      );
      if (cartItem) {
        dispatch(addToCart(cartItem));
        setIsCartModalOpen(true);
        setCartItem(cartItem);
      }
      dispatch(setIsEditing(false));
      // If the current ProductConfig is not in the cart keep the id so it can be re-selected
      // later (for example "continue shopping" from cart view).
      if (!isConfigInCart) {
        dispatch(setPreviousProductConfigId(configId));
      }
    } else {
      console.warn(response);
    }
  }, [
    dispatch,
    fabrics,
    productGroups,
    productOptions,
    products,
    configId,
    addToCartMutation,
    isConfigInCart,
  ]);

  // Add to cart
  const updateCartProduct = useCallback(async () => {
    if (!configId) {
      return;
    }
    const cartItem: Partial<CartProduct> = pick(config, [
      "fabric",
      "fabricSupplier",
      "productOptions",
      "productModelPicture",
      "productVariant",
    ]);

    // Update api ProductConfig
    await updateProductConfig({ config });
    // Update api ProductConfig Image
    if (config.productModelPicture) {
      updateProductConfigProductImage({
        config: {
          id: configId,
          productModelPicture: config.productModelPicture,
        },
      });
    }

    // Update cart.products in the redux store
    dispatch(updateProduct({ id: configId, cartProduct: cartItem }));

    // Show cart view
    navigate(`/cart/${projectId}`);
  }, [
    dispatch,
    config,
    configId,
    projectId,
    updateProductConfig,
    updateProductConfigProductImage,
    navigate,
  ]);

  // Save config on change
  useEffect(() => {
    if (isEditing || isConfigInCart) {
      return;
    }
    // Only save when config is fully ready
    if (
      isLoading ||
      !config.projectId ||
      !config.productGroup ||
      !config.product ||
      !config.fabric ||
      !config.productOptions
    ) {
      return;
    }

    // Prevent patch of ProductConfig after initial load
    if (!previousConfig || !previousConfig.fabric) {
      return;
    }

    // Exclude productModelPicture from comparison because it triggers unneeded updates
    const exclude = ["productModelPicture"];
    if (!isEqual(omit(previousConfig, exclude), omit(config, exclude))) {
      saveConfig();
    }
  }, [
    dispatch,
    config,
    configId,
    isLoading,
    previousConfig,
    saveConfig,
    isEditing,
    isConfigInCart,
  ]);

  return (
    <div className="flex flex-col justify-center">
      <div className="text-center my-4">
        <div className="mb-0 justify-center flex flex-wrap gap-2">
          <Title label="unit_price" />
          <Title
            className={discount ? "line-through" : ""}
            label={`${cartItemPrice.toFixed(2)}€`}
          />
          {discount && (
            <Title label={`${discountedCartItemPrice.toFixed(2)}€`} />
          )}
        </div>
        <p className="text-text text-sm">
          <PrismicTranslation
            field={"min_order_quantity"}
            options={{
              min_order_quantity: getMininumOrderQuantity(settings),
            }}
            simple
          />
        </p>
      </div>
      <Button
        loading={isLoading}
        full
        icon="cart"
        label={isConfigInCart ? "save_changes" : "add_to_cart"}
        onClick={isConfigInCart ? updateCartProduct : addProductToCart}
      />
      {isCartModalOpen && cartItem && (
        <AddToCartModal
          isOpen={isCartModalOpen}
          setIsOpen={setIsCartModalOpen}
          cartItem={cartItem}
        />
      )}
    </div>
  );
};

export default ConfiguratorFooter;
